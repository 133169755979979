import React, { useState, useEffect } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import NavDrawer from "./NavDrawer";

const AppHeader = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [username, setUsername] = useState("");

    useEffect(() => {
        const url = process.env.REACT_APP_FLEET_MANAGER_URL + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_CURRENT_USER
        fetch(url, {method: 'GET'})
            .then(response => {
                return response.json();
            })
            .then(response => {
                setUsername(response.first_name === "" ?  response.username : response.first_name)
            });
    }, []);

  return (
      <div>
          <Box sx={{ display: 'flex' }}>
              <AppBar style={{ background: '#39B54A' }} position="sticky">
                  <Toolbar sx={{ justifyContent: 'space-between' }}>
                      <Grid container spacing={1}>
                          <Grid item xs={4} >
                              {
                                  (drawerOpen)
                                  ? <MenuOpenIcon onClick={() => {setDrawerOpen(false)}} />
                                  : <MenuIcon onClick={() => {setDrawerOpen(true)}} />
                              }
                          </Grid>
                          <Grid item align={"center"} xs={4}>
                              <Typography>Chamber Manager</Typography>
                          </Grid>
                          <Grid item align={"right"} xs={4}>
                              <Typography>Welcome: {username}</Typography>
                          </Grid>
                      </Grid>
                  </Toolbar>
              </AppBar>
            <NavDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}></NavDrawer>
          </Box>
      </div>
  );
}

export default AppHeader;