import './css/common.css'

import React, { useEffect } from "react"
import logo from "../ripelocker-logo.png";

const Forbidden = () => {
    window.location.href = process.env.REACT_APP_AUTH_URL;
}

export default Forbidden;
