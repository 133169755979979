import 'react-dropdown/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/LoadChamberView.css';
import './css/common.css';

import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker'
import Dropdown from 'react-dropdown'
import toast, { Toaster } from 'react-hot-toast';
import { getLocations } from "./utils/utils";
import Header from "./utils/Header";

const LoadChamberView = () => {
    const [locations, setLocations] = useState({});
    const [systems, setSystems] = useState({});
    let chambers = Array.from({length: 20}, (_, i) => i + 1).map(String);
    const [commodities, setCommodities] =  useState({});
    const [varieties, setVarieties] = useState([]);
    let commodityId = null;

    const [userChoices, setUserChoices] = useState({
        location: locations.length === 1 ? locations[0] : "",
        locationValid: locations.length === 1,
        system: systems.length === 1 ? systems[0] : "",
        systemValid: systems.length === 1,
        chamber: "",
        chamberValid: false,
        commodity: commodities.length === 1 ? commodities[0] : "",
        commodityValid: commodities.length === 1,
        variety: varieties.length === 1 ? varieties[0] : "",
        varietyValid: varieties.length === 1,
        pickedDate: new Date()
    });

    useEffect(() => {
        getLocations().then(response => {
            setLocations(response);
        });
    }, []);

    useEffect(() => {
        if (Object.keys(locations).length === 1) {
            handleSelectLocation(Object.keys(locations)[0])
        }
    }, [locations]);

    const onSubmitButtonClick = () => {
        const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ variety: varieties[userChoices.variety].id, "controller": systems[userChoices.system].id, "location": locations[userChoices.location].id, "slot": Number(userChoices.chamber) })
            };
        const url = process.env.REACT_APP_FLEET_MANAGER_URL + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_LOADINGS
        let promise = fetch(url, requestOptions)
        toast.promise(
            promise,
            {
                loading: 'Submitting...',
                success: (data) => `Success!`,
                error: (err) => `Error: ${err.toString()}`,
            },
        );
    }

    const handleSelectLocation = async (selection) => {
        let url = process.env.REACT_APP_FLEET_MANAGER_URL
            + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_CONTROLLERS_LOCATION
            + locations[selection].id
        await fetch(url, {method: 'GET'})
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (response.length === 0) {
                            toast.error("No systems at location");
                            setUserChoices(previousState => {
                                return { ...previousState, location: selection, locationValid: false }
                            });
                        }
                else {
                    setUserChoices(previousState => {
                        return { ...previousState, location: selection, locationValid: true }
                    });
                    let systems_json = {}
                    for (let i = 0; i < response.length; i++) {
                         systems_json[response[i].serial_no] = {
                             "id": response[i].id,
                             "name": response[i].name,
                             "location": response[i].location
                         }
                    }
                    setSystems(systems_json);
                }
            });

        url = process.env.REACT_APP_FLEET_MANAGER_URL +
            process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_COMMODITIES_VARIETIES
        await fetch(url, {method: 'GET'})
            .then(response => {
                return response.json();
            })
            .then(response => {
                let commodities_json = {}
                for (let i = 0; i < response.length; i++) {
                    commodities_json[response[i].commodity.name] = response[i].commodity.id
                }
                setCommodities(commodities_json);
            });
    }

    const handleCommoditySelection = async (selection) => {
        const url = process.env.REACT_APP_FLEET_MANAGER_URL +
            process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_COMMODITIES_VARIETIES
        await fetch(url, {method: 'GET'})
            .then(response => {
                return response.json();
            })
            .then(response => {
                let varieties_json = {}
                commodityId = response[0].commodity.id
                setUserChoices(previousState => {
                    return { ...previousState, commodity: selection.value, commodityValid: true }
                });
                for (let i = 0; i < response.length; i++) {
                    if (response[i].commodity.name === selection.value) {
                        varieties_json[response[i].name] = {
                            "id": response[i].id,
                            "commodity": response[i].commodity,
                            "product_code": response[i].product_code
                        }
                    }
                }
                setVarieties(varieties_json);
            });
    }

    return (
        <div>
            <Header />
            <div className="load-container">
                <div className="selection-container">
                    <Dropdown
                        options={Object.keys(locations)}
                        onChange={(selection) => {
                            handleSelectLocation(selection.value)
                        }}
                        value={userChoices.location}
                        placeholder="Location"
                    />
                </div>
                <div className="selection-container">
                    <Dropdown
                        options={Object.keys(systems)}
                        disabled={!(userChoices.locationValid)}
                        onChange={(selection) => {
                            setUserChoices(previousState => {
                                return {...previousState, system: selection.value, systemValid: true}
                            });
                        }}
                        value={userChoices.system}
                        placeholder="System"
                    />
                </div>
                <div className="selection-container">
                    <Dropdown
                        options={chambers}
                        disabled={!userChoices.locationValid}
                        onChange={(selection) => {
                            setUserChoices(previousState => {
                                return { ...previousState, chamber: selection.value, chamberValid: true }
                            });
                        }}
                        value={userChoices.chamber}
                        placeholder="Chamber"
                    />
                </div>
                <div className="selection-container">
                    <Dropdown
                        options={Object.keys(commodities)}
                        disabled={!userChoices.locationValid}
                        onChange={(selection) => {
                            handleCommoditySelection(selection)
                        }}
                        value={userChoices.commodity}
                        placeholder="Commodity"
                    />
                </div>
                <div className="selection-container">
                    <Dropdown
                        options={Object.keys(varieties)}
                        disabled={!userChoices.locationValid}
                        onChange={(selection) => {
                            setUserChoices(previousState => {
                                return { ...previousState, variety: selection.value, varietyValid: true }
                            });
                        }}
                        value={userChoices.variety}
                        placeholder="Variety"
                    />
                </div>
                <div className="label-date-container">
                    <div className="label-container">
                        Picked Date
                    </div>
                    <div className="date-selection-container">
                        <DatePicker withPortal
                                    inline
                                    selected={userChoices.pickedDate}
                                    disabled={!userChoices.locationValid}
                                    onChange={(date) => {
                                        setUserChoices(previousState => {
                                            return { ...previousState, pickedDate: date }
                                        });
                                    }}>
                        </DatePicker>
                    </div>
                </div>
                <div className="seal-button-container">
                    <button id="seal-button"
                            className="button-container"
                            type="button"
                            disabled={!(userChoices.locationValid &&
                                        userChoices.systemValid &&
                                        userChoices.chamberValid &&
                                        userChoices.commodityValid &&
                                        userChoices.varietyValid)}
                            onClick={()=> onSubmitButtonClick()}>
                Submit
                    </button>
                </div>
                <Toaster />
            </div>
        </div>
    )
}

export default LoadChamberView;