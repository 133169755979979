import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // call this function when you want to authenticate the user
  const handleAuth = async () => {
      let url = process.env.REACT_APP_FLEET_MANAGER_URL + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_CONTROLLERS
      await fetch(url, {method: 'GET'})
          .then(response => {
              setIsAuthenticated(response.status === 200)
          });
  };

  const logout = () => {
      setIsAuthenticated(false);
      window.location.href = process.env.REACT_APP_AUTH_URL;
  };

  const value = useMemo(
    () => ({
      isAuthenticated,
      handleAuth,
      logout
    }),
    [isAuthenticated]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};