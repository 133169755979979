export const getLocations = async () => {
    const url = process.env.REACT_APP_FLEET_MANAGER_URL + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_COMPANY_LOCATIONS
    return await fetch(url, {method: 'GET'})
        .then(response => {
            return response.json();
        })
        .then(response => {
            let locations_json = {}
            for (let i = 0; i < response.length; i++) {
                locations_json[response[i].name] = {"id": response[i].id, "city": response[i].city}
            }
            return locations_json;
        });
}