import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";

const NavDrawer = (props) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        const url = process.env.REACT_APP_LOGOUT_URL
        const requestOptions = {
                method: 'GET',
            };
        await fetch(url, requestOptions).then(() => {
            window.location.replace(process.env.REACT_APP_AUTH_URL);
        })
    }

    const closeDrawer = () => {
        props.setDrawerOpen(false);
    }

    return (
        <div>
            <Drawer
                anchor="left"
                open={props.drawerOpen}
                onClose={closeDrawer}
                width={200}
            >
                <List
                    component="nav"
                    aria-labelledby="nested-list-staff-subheader"
                    disablePadding
                >
                    <ListItem button onClick={() => {
                        navigate(process.env.REACT_APP_ROUTE_HOME);
                    }}>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText key="home-nav-button" primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => {
                        navigate(process.env.REACT_APP_ROUTE_LOAD_CHAMBER_VIEW);
                    }}>
                        <ListItemText key="load-chamber-nav-button" primary="Load Chamber" />
                    </ListItem>
                    <ListItem button onClick={() => {
                        navigate(process.env.REACT_APP_ROUTE_SERVICE_MODE_VIEW);
                    }}>
                        <ListItemText key="service-mode-nav-button" primary="Service Mode" />
                    </ListItem>
                    <ListItem button onClick={() => {
                        let url = process.env.REACT_APP_FLEET_MANAGER_URL
                            + process.env.REACT_APP_FLEET_MANAGER_ENDPOINT_ADMIN;
                        window.location.href = url;
                    }}>
                        <ListItemText key="fleet-manager-admin-nav-button" primary="Fleet Manager" />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemText key="logout-nav-button" primary="Log Out" />
                    </ListItem>
                </List>
            </Drawer>
        </div>
  );
}

export default NavDrawer;